import * as React from 'react';
const { useState, useEffect, useCallback } = React;

const loadingPhrases = {
  default: [
    'Igniting the boiler...',
    'Firing up the engines...',
    'Warming up Firebase...',
    'Compiling cutting-edge code...',
    'Loading modern frameworks...',
  ],
  missions: [
    'Preparing your project blueprint...',
    'Gathering integrations...',
    'Setting up social connections...',
    'Configuring AI modules...',
    'Finalizing templates...',
  ],
  telegramConnect: [
    'Linking Telegram integrations...',
    'Establishing chat connections...',
    'Synchronizing social data...',
    'Bridging modern communication...',
    'Loading interactive bots...',
  ],
  guest: [
    'Setting up a trial instance...',
    'Configuring guest access...',
    'Building a temporary project...',
    'Initializing demo mode...',
    'Preparing a sample app...',
  ],
};

const loadingStates = {
  default: [
    'Initializing Firebase...',
    'Authenticating project...',
    'Loading integrations...',
    'Preparing project structure...',
    'Finalizing setup...',
  ],
  guest: [
    'Configuring guest project...',
    'Preparing limited access...',
    'Loading demo features...',
    'Setting up trial environment...',
    'Finalizing guest setup...',
  ],
};

const LOADING_TIMEOUT = 30000; // 30 seconds

const LoadingScreen = ({
  type = 'default',
  error = null,
  customMessage = '',
  onTimeout = () => {},
  isGuest = false,
}) => {
  const [phrase, setPhrase] = useState('');
  const [progress, setProgress] = useState(0);
  const [currentState, setCurrentState] = useState(0);

  const updatePhrase = useCallback(() => {
    const phrases = loadingPhrases[isGuest ? 'guest' : type] || loadingPhrases.default;
    setPhrase(phrases[Math.floor(Math.random() * phrases.length)]);
  }, [type, isGuest]);

  const updateLoadingState = useCallback(() => {
    const states = isGuest ? loadingStates.guest : loadingStates.default;
    setCurrentState((prevState) =>
      prevState < states.length - 1 ? prevState + 1 : prevState
    );
  }, [isGuest]);

  useEffect(() => {
    updatePhrase();
    const phraseInterval = setInterval(updatePhrase, 5000);
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + Math.random() * 3;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 200);
    const stateInterval = setInterval(updateLoadingState, 3000);

    const timeoutId = setTimeout(() => {
      onTimeout();
    }, LOADING_TIMEOUT);

    return () => {
      clearInterval(phraseInterval);
      clearInterval(progressInterval);
      clearInterval(stateInterval);
      clearTimeout(timeoutId);
    };
  }, [type, isGuest, customMessage, updatePhrase, updateLoadingState, onTimeout]);

  if (error) {
    return (
      <div className="loading-screen error">
        <div className="loading-content">
          <h2>Error</h2>
          <p>{error}</p>
          <p>Please refresh the page and try again.</p>
        </div>
      </div>
    );
  }

  const currentLoadingStates = isGuest ? loadingStates.guest : loadingStates.default;

  return (
    <div
      className="loading-screen"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div className="loading-content">
        <div className="spinner"></div>
        <h2>{progress >= 100 ? 'Almost there...' : 'Loading...'}</h2>
        <p className="loading-phrase">{phrase}</p>
        <p className="loading-state">
          {customMessage ||
            (progress >= 100 ? 'Final touches...' : currentLoadingStates[currentState])}
        </p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <p className="loading-footer">
          {isGuest
            ? 'Please wait while we prepare your trial experience...'
            : 'Please wait while we fire up your project...'}
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;
